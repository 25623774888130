import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Tag from '../Tag'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tagList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
}))

export default function TagList({ tags }) {
  const classes = useStyles()

  return (
    <Paper component="ul" elevation={0} className={classes.tagList}>
      {tags.map((tag, index) => (
        <li key={`${tag}${index}`}>
          <Tag label={tag} />
        </li>
      ))}
    </Paper>
  )
}

TagList.propTypes = {
  /**
   * A list of tags
   */
  tags: PropTypes.arrayOf(PropTypes.string),
}
