import {
  BLOG_POSTS_FRONT_MATTER_DEFAULT_PROPS,
  BLOG_POSTS_FRONT_MATTER_PROP_TYPES,
} from '../../utils/constants/frontmatter'
import { Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

import PropTypes from 'prop-types'
import React from 'react'
import TagList from '../blog/TagList'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '2rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.light,
    paddingBottom: theme.spacing(1),
  },
  timeToReadAndDate: {
    marginBottom: '0.5rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
  excerpt: {
    color: grey[600],
    fontSize: '1.125rem',
    marginBottom: theme.spacing(1),
  },
  tagList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  readMore: {
    color: theme.palette.primary.light,
    display: 'inline-block',
    position: 'relative',
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s',
  },
  svgArrow: {
    verticalAlign: 'middle',
    lineHeight: 1.5,
    marginLeft: '0.2em',
  },
}))

export default function BlogPostCard({ frontmatter, excerpt, timeToRead }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {frontmatter.title}
      </Typography>
      <Typography
        color="textSecondary"
        variant="subtitle2"
        className={classes.timeToReadAndDate}
      >
        {`${frontmatter.updatedAt}${
          timeToRead !== null ? ` • ${timeToRead} min read` : ''
        }`}
      </Typography>
      <Typography className={classes.excerpt}>{excerpt}</Typography>
      <TagList tags={frontmatter.tags} />
      <p className={classes.readMore}>
        Read More
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          className={classes.svgArrow}
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
        </svg>
      </p>
    </div>
  )
}

BlogPostCard.propTypes = {
  /**
   * Frontmatter prop types
   */
  frontmatter: PropTypes.shape({ ...BLOG_POSTS_FRONT_MATTER_PROP_TYPES }),

  /**
   * An excerpt from the post
   */
  excerpt: PropTypes.string.isRequired,

  /**
   * Reading time
   */
  timeToRead: PropTypes.number,
}

BlogPostCard.defaultProps = {
  frontmatter: BLOG_POSTS_FRONT_MATTER_DEFAULT_PROPS,
  timeToRead: 1,
}
