import { Box, Link } from '@material-ui/core'

import BlogPostCard from '../components/templates/BlogPostCard'
import { Link as GatsbyLink } from 'gatsby'
import PagesLayout from '../components/layouts/PagesLayout'
import React from 'react'
import { graphql } from 'gatsby'

export default function Blog({ data: { allMdx } }) {
  const { edges: posts } = allMdx

  return (
    <PagesLayout SEO={{ title: "flvyu's Blog" }}>
      <Box display="flex" justifyContent="center" p={2}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-start"
          maxWidth={600}
        >
          {posts
            .filter(({ node: post }) => post.frontmatter.isVisible)
            .map(({ node: post }) => (
              <Box my={0.5} key={post.id}>
                <Link
                  to={post.fields.slug}
                  component={GatsbyLink}
                  underline="none"
                >
                  <BlogPostCard
                    frontmatter={post.frontmatter}
                    excerpt={post.excerpt}
                    timeToRead={post.timeToRead}
                  />
                </Link>
              </Box>
            ))}
        </Box>
      </Box>
    </PagesLayout>
  )
}

export const query = graphql`
  {
    allMdx(
      sort: {
        fields: [frontmatter___updatedAt, frontmatter___title]
        order: DESC
      }
      filter: { fileAbsolutePath: { regex: "/(blog-posts)/" } }
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            shortDescription
            isVisible
            updatedAt(formatString: "MMMM DD, YYYY")
            createdAt(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
  }
`
