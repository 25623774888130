import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 1, 1, 0),
    textTransform: 'uppercase',
    letterSpacing: 0.8,
    color: '#000',
    backgroundColor: theme.palette.primary2.main,
  },
}))
export default function Tag({ label }) {
  const classes = useStyles()
  return (
    <Box textAlign="left" className={classes.root} fontSize={12}>
      {label}
    </Box>
  )
}
